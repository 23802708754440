//
//

$icon-font-path: '/fonts/bootstrap/'; // see also webpack.mix.js

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.container {
  font-size: 14px;
}

.dataTable {
  font-size: 14px;
}

.form-haku {
  margin-top: 30px;
  margin-right: 20px;

  .simple-haku-input {
    width: 400px;
  }

  .haku-submit {
    width: 7em;
  }

  .extra-help-text {
    font-size: 0.9em;
    color: grey;
  }

}

.form-inline-haku {
  text-align: left;

  .row {
    padding-top: 10px;
  }

  .haku-input {
    width: 100%;
  }

}

.haku-switcher {
  margin-right: 20px;
}


.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>thead>tr>th {
  border: none;
}

table {
  font-size: 14px;
}

.position-relative {
  position: relative;

  .top-right-corner {
    top: 10px;
    right: 50px;
    position: absolute;
  }
}

.tuote-table {

  tr td:first-child {
    font-weight: bold;
    text-align: right;
  }

  .separator {
    border-bottom: 1px solid black;
  }

  .separator-top {
    border-top: 1px solid black;
  }

  .muutoshistoria {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }

  .muutoshistoria:last-child {
    border-bottom: none;
  }

  .tuotetapahtumat-div {
    padding: 30px;

    .tuotetapahtumat-table {
      tr td:first-child {
        font-weight: normal;
        text-align: left;
      }
    }

  }

  .muutos-label {
    padding-right: 22px;
  }
}

.details-table {
  td {
    padding: 2px 5px 2px 5px;
  }
}

a.black-link:hover,
a.black-link:visited,
a.black-link:link,
a.black-link:active {
  color: #333;
  text-decoration: none;
}

a.black-link.disabled:hover,
a.black-link.disabled:visited,
a.black-link.disabled:link,
a.black-link.disabled:active {
  visibility: hidden;
  text-decoration: none;
  pointer-events: none;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.catalog-data-queries-div {
  padding: 0px 20px;
}

.wide {
  padding-left: 5px;
  padding-right: 5px;
}

.inline {
  display: inline;
}

.gray-round-box {
  padding: 10px;
  border: 1px solid #B2B2B2;
  border-radius: 5px;
  margin-bottom: 10px;
}

.title-in-box {
  position: relative;
  margin-left: 1em;
  display: inline;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 20px;
  top: -20px;
  color: pink;
}

.catalog-data-change-plain {
  margin-bottom: 5px;
}

.catalog-data-change-show-hide {
  font-weight: normal;
}

.dataTable {
  .show-child-row {
    position: relative;
  }

  .show-child-row:hover,
  .show-child-row:visited,
  .show-child-row:active,
  .show-child-row:link {
    text-decoration: none;
  }

  .show-child-row:before {
    font-family: FontAwesome;
    top: 0;
    left: -5px;
    padding-right: 5px;
    content: "\f055";
    color: green;
  }

  tr.shown .show-child-row:before {
    font-family: FontAwesome;
    top: 0;
    left: -5px;
    padding-right: 5px;
    content: "\f056";
    color: red;
  }
}

.dataTables_wrapper .dataTables_filter input::placeholder {
  color: #ccc;
}

.recent-content {
  padding-left: 20px;
  margin-bottom: 20px;

  .query-short {
    margin: 5px 0;
    padding: 5px;
    border: 1px solid #eee;
    border-radius: 5px;
  }
}

.boldest {
  font-weight: 900;
}

.bottom-gap {
  margin-bottom: 10px;
}

.pre-wrap {
  white-space: pre-wrap
}

.luettelointi-summary {
  .widget-blue {
    background-color: #5bc0de;
  }

  .widget-green {
    background-color: #5cb85c;
  }

  .widget-red {
    background-color: #d9534f;
  }

  .widget-violet {
    background-color: #a366ba;
  }

  .font-blue {
    color: #5bc0de;
  }

  .font-green {
    color: #5cb85c;
  }

  .font-red {
    color: #d9534f;
  }

  .font-violet {
    color: #a366ba;
  }


  .widget {
    color: white;
    font-weight: bold;
    padding: 20px;
    border-radius: 10px;
    margin-top: 5px;

    h2 {
      margin-top: 5px;
    }

    hr {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .info {
      font-weight: normal;
      font-size: 12px;
    }

    .one-liner {
      font-size: 18px;
    }
  }
}

.login {
  padding: 200px 10px 0px;

  .textfield {
    width: 120px;
    font-size: 11px;
    color: #333333;
    background: #F7F7F7;
    border: 1px solid #CCCCCC;
    padding-left: 0px;
  }

}

.catalog-data-changes-pagination .pagination {
  margin-top: 0px;
}

.muutos-button {
  float: right;
  margin-top: -10px;
  margin-bottom: 20px;
}

.embedded-catalog-data-queries-div div.row {
  padding-top: 15px;
}

.embedded-catalog-data-queries-div div.row:hover {
  background: rgba(221, 238, 255, 0.4);
}

.round-box-danger {
  border-radius: 5px;
  color: #fff;
  background-color: #d9534f;
  padding: 5px;
}

.round-box-info {
  border-radius: 5px;
  color: #fff;
  background-color: #5bc0de;
  padding: 5px;
}

.round-box-success {
  border-radius: 5px;
  color: #fff;
  background-color: #5cb85c;
  padding: 5px;
}

.round-box-warning {
  border-radius: 5px;
  color: #fff;
  background-color: #eeae41;
  padding: 5px;
}

.round-box-white {
  border-radius: 5px;
  color: #50a150;
  background-color: #fff;
  padding: 5px;
}

.round-box-light-green {
  border-radius: 5px;
  color: #333;
  background-color: #90e790;
  padding: 5px;
}

.eee-border {
  border: 1px solid #eee;
}

.round-box-black {
  border-radius: 5px;
  color: #333;
  background-color: #fff;
  border: 1px solid #333;
  padding: 5px;
}

.badge-danger {
  color: #d9534f !important;
  background-color: #fff !important;
}

.badge-info {
  color: #5bc0de !important;
  background-color: #fff !important;
}

.badge-success {
  color: #5cb85c !important;
  background-color: #fff !important;
}

.kooste {
  ul {
    list-style-type: disc;
    padding-left: 1em;
  }
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.aineistolaji-row {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  line-height: 200%;

  .product-group-span {
    border: 1px solid #333;
    border-radius: 10px;
    padding: 5px;
    font-size: 12px;
    color: black;
    background-color: #ddd;
    white-space: pre;
  }
}

.btn-grey {
  background-color: #efefef;
  color: #333;
}

.btn-grey:hover {
  background-color: #ddd;
}

.white-border {
  border-color: white;
}

/* button classes */
.outline {
  background-color: transparent;
  color: inherit;
  transition: all .25s;
}

.btn-default.outline {
  color: #ccc;
}

.btn-success.outline {
  color: #4cae4c;
}

.btn-info.outline {
  color: #5bc0de;
}

.btn-warning.outline {
  color: #f0ad4e;
}

.btn-danger.outline {
  color: #d9534f;
}

.btn-default.outline:hover,
.btn-default.outline:active,
.btn-primary.outline:hover,
.btn-primary.outline.active,
.btn-success.outline:hover,
.btn-success.outline.active,
.btn-info.outline:hover,
.btn-info.outline.active,
.btn-warning.outline:hover,
.btn-warning.outline.active,
.btn-danger.outline:hover,
.btn-danger.outline.active {
  color: #fff;
}


.btn-circle {
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  padding-top: 15px;
}


.user-authorizations-table {
  font-size: 11px;

  tr {
    background-color: rgba(0, 0, 0, 0) !important;

    td {
      padding: 4px 10px !important;
    }
  }
}

/* Vue.js CSS rules: */
[v-cloak] {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

/* Collection (Lista) rules: */
.collection-green-button {
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 2px;
  color: #4cae4c;
}

.collection-green-button:hover {
  background-color: #57c757;
  color: white;
}

/* Filter buttons: */
.filters-info-button {
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 2px;
  color: #5bc0de;
}

.filters-info-button:hover {
  background-color: #3ac9f2;
  color: white;
}

/* Filter buttons: */
.filters-black-button {
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 2px;
  color: #333;
  border-color: #333;
}

.filters-black-button:hover {
  background-color: #333;
  color: white;
}

.filters-black-button.outline:hover,
.filters-black-button.outline.active {
  background-color: #333;
  color: #fff;
}

.active-info-button {
  background-color: #5bc0de !important;
  border-color: #5bc0de !important;
  color: white !important;
}

.active-success-button {
  background-color: #4cae4c !important;
  border-color: #4cae4c !important;
  color: white !important;
}

.active-black-button {
  background-color: #333 !important;
  border-color: #333 !important;
  color: white !important;
}

/* collection item list view */
.collection-item {
  /*border: 0px;*/
  border: 1px solid #ccc;
  padding: 40px 20px 20px 30px;
  min-height: 270px;
  /*max-height: 270px;*/
  margin-bottom: 20px;
}

.collection-item:hover {
  box-shadow: 0px 0px 8px 3px #f0f0f0;
}

/* Product list 'grid' view */
.collection-item-odd {
  background-color: #f7f7f7;
}

.collection-item-fade-out-description {
  max-height: 140px;
  overflow: hidden;
}

.collection-item-fade-out-description:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(rgba(255, 255, 255, 0) 100px, white);
}

.white-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  ;
}

.arvo-notice {
  font-size: 18px;
  background-color: white;
  padding: 20px;
  border: 5px solid #00a69c;
  border-radius: 10px;
  ;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
}

.arvo-notice {

  a,
  a:visited,
  a:hover,
  a:active {
    color: #EF483E;
  }
}

.small_scaled_up {
  transform: scale(1.2)
}

.capitalized_label {
  display: inline-block;
}

.capitalized_label:first-letter {
  text-transform: uppercase;
}

.capitalized {
  text-transform: capitalize;
}

.m-r-10 {
  margin-right: 10px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-hover {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(5);
    border: 1px solid white;
  }
}

.check-mark {
  &::before {
    content: "\2713 ";
  }
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 150px;
  height: 150px;
  text-align: right;
}

.ribbon span {
  font-size: 18px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 35px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 200px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#F79E05 0%, #8F5408 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 41px;
  right: -41px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #8F5408;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8F5408;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #8F5408;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8F5408;
}